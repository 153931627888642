import React, { useEffect } from 'react';
import ReactMarkdown from "react-markdown/with-html";
import $ from "jquery";
import DateFormat from '@components/format-date/';
import ImgBox from '@components/img-box/img-box';
import { Logo } from '@components/icon/icon';
import { Container } from 'react-bootstrap';

/**
 * Assets
 */
import './styles/_index.scss'

/**
 * Components
 */

import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import SEO from "@components/seo";
const SINGLE_NEWS = gql`
query GetNewsDetails ($URL: String!){
  newsEvents (where:{URL: $URL}){
    Title
    Published_Date
  }
}
`;


function NewsIntro(props) {
const { loading, error, data } = useQuery(SINGLE_NEWS, {
    variables: { URL: props.DetailURL }
  });
  if (typeof window !== "undefined") {
  $(window).scroll(function () {
      var sticky = $('.sticky-header'),
          scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
  });
  }
  if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
  </div>);
  return (
    <>
    {data.newsEvents.map((node, key) => {
      return<>
      <SEO title={node.Title} description={'Read about '+node.Title+' here and Subscribe to get all of our latest news and insights.'} />
        <div className="breadcrumbs-intro">
            <Intro>
            <Breadcrumbs alias="blog" detailTitle={node.Title}/>
            <div className="intro__text">
                <h1>{node.Title}</h1>
                <p><DateFormat date={node.Published_Date} /></p>
            </div>
            </Intro>
        </div>
        </>
      })}
    </>
  )
}

export default NewsIntro
