import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Row, Container } from 'react-bootstrap';
import $ from "jquery";
import { Link } from 'gatsby';
import ReactMarkdown from "react-markdown/with-html"
import { useLocation } from "@reach/router"
import ImgBox from '@components/img-box/img-box';
import { Logo } from '@components/icon/icon';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import { Twitter, Facebook, Instagram, Linkedin, ArrowDown } from '@components/icon/icon'
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';

const SINGLE_NEWS = gql`
query GetNewsDetails ($URL: String!){
  newsEvents (where:{URL: $URL}){
    Content
  }
}
`;

function NewsContent(props) {
    const location = useLocation();
    const { loading, error, data } = useQuery(SINGLE_NEWS, {
        variables: { URL: props.DetailURL }
    });
    const active = props.active;


    let AOS;
    useEffect(() => {
        const AOS = require("aos");
        AOS.init({
            once: true,
            disable: 'mobile'
        });
    }, [])

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }

        var win = $(window);
        win.scroll(function () {
            var sticky = $('.sticky-header'),
                scroll = win.scrollTop();

            if (scroll >= 40) sticky.addClass('fixed');
            else sticky.removeClass('fixed');
        });
    });
    if (loading) return <p>Loading ...</p>;

    return (
        <>
            {data.newsEvents.map((node, key) => {
                return <>
                    <div className="news-content">
                        <Container>
                            <Row className="justify-content-between">
                                <Col lg="8">
                                    <ReactMarkdown source={node.Content} escapeHtml={false} />

                                    <h3>Share this Post</h3>

                                    <div className="socials">
                                        <ul>
                                            <li>                      <a href={`https://www.facebook.com/sharer?u=${location.href}`} target="_blank">

                                                <span>Facebook</span> <Facebook /></a></li>
                                            <li>                      <a href={`https://twitter.com/intent/tweet?url=${location.href}`} target="_blank">

                                                <span>Twitter</span> <Twitter /></a></li>
                                            <li><a href="#" target="_blank"><span>Instagram</span> <Instagram /></a></li>
                                            <li><a href={`https://www.linkedin.com/cws/share?url=${location.href}`} target="_blank"><span>Linkedin</span> <Linkedin /></a></li>
                                        </ul>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            })}
        </>
    );
}

export default NewsContent
