import React from 'react';
import { Col, Container, Row, Carousel } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import ImgBox from '@components/img-box/img-box'
import Slider from 'react-slick';
import DateFormat from '@components/format-date/';
import { Logo } from '@components/icon/icon';
import { Link } from 'gatsby';


/**
 * Components
 */
import PropertyBox from '@components/property-box/property-box';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

/**
 * Assets
 */
import "slick-carousel/slick/slick.css";
import './styles/_index.scss';

import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
const GET_ALL_NEWS = gql`
query GETNEWS ($URL: String!){
  newsEvents (where:{URL_ne: $URL}){
    id
    Title
    URL
    Banner_Image {
      url
    }
    Published_Date
    imagetransforms
  }
}
`;
const NewsOtherPost = (props) => {


    const { loading, error, data } = useQuery(GET_ALL_NEWS, {
        variables: { URL: props.DetailURL }
    });

    if (loading) return <p>Loading ...</p>;
    
    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };
    return (
        <div className="properties-boxes other-news-blog" data-aos="fade-up">
            <Container>
                <h2>Other posts that may interest you</h2>
                {/* <Row className="d-none d-lg-flex"> */}
                    <div className="slider-holder">
                        <Slider {...settings}>
                            {data.newsEvents.map((item, index) => {
                                let processedImages = JSON.stringify({});
                                if (item?.imagetransforms?.Banner_Image_Transforms) {
                                    processedImages = item.imagetransforms.Banner_Image_Transforms;
                                }
                                return <>
                                    <div className="property-slider-box">
                                    
                                        <div className="property-box">
                                            <div className="property-box__image">
                                            {item.Banner_Image !=null &&
                                            <Link href={`${item.URL}/`}>
                                            <ImageTransform imagesources={item.Banner_Image.url} renderer="srcSet" imagename="news-events.Banner_Image.slidetile"attr={{ alt: item.Title+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={item.id}/>
                                            </Link>
                                            }
                                            </div>
                                            <div className="property-box__content">
                                                <p><DateFormat date={item.Published_Date} /></p>
                                                <Link href={`${item.URL}/`}>
                                                    <h4>{item.Title}</h4>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            })}
                        </Slider>
                    </div>
                {/* </Row> */}
            </Container>
        </div>
    )
}

export default NewsOtherPost
