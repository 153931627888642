import React from 'react';
import classNames from 'classnames'
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import { Col, Row, Container } from 'react-bootstrap';
import ImgBox from '@components/img-box/img-box';

/**
 * Assets
 */

import './styles/_index.scss';

/**
 * Components
 */
import ContactBox from '@components/contact-box/contact-box';
import Sidebar from '@components/sidebar/sidebar';
import NewsletterForm from '@components/forms/newsletter-form';
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";

import gql from "graphql-tag"
import { useQuery } from '@apollo/client';

const SINGLE_NEWS = gql`
query GetNewsDetails ($URL: String!){
  newsEvents (where:{URL: $URL}){
    Title
    id
    Banner_Image {
      url
    }
    imagetransforms
    teams {
      Name
      Designation
      Phone
      Image {
        url
      }
    }
  }
}
`;

function NewsWave(props) {
    const { loading, data } = useQuery(SINGLE_NEWS, {
    variables: { URL: props.DetailURL }
  });
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    
    if (loading) return <p>Loading ...</p>;

    return (
        <div className="static-wave news-wave">
            <div className={classNames('wave news-wave', { 'is-active': inView })} ref={ref}>
                <Container>
                    <Row className="justify-content-between">
                    {data.newsEvents.map((node) => {
                      let processedImages = JSON.stringify({});
                      if (node?.imagetransforms?.Banner_Image_Transforms) {
                          processedImages = node.imagetransforms.Banner_Image_Transforms;
                      }
                      return<>

                        <Col lg="8">
                            <div className="image">
                                <ImageTransform imagesources={node.Banner_Image.url} renderer="srcSet" imagename="news-events.Banner_Image.commontile"attr={{ alt: node.Title+ ' - Anthony Pepe' }} imagetransformresult={processedImages} id={node.id}/>

                            </div>
                        </Col>
       
                        <Col lg="4">
                            <Sidebar>
                                <NewsletterForm />
                                {node.teams.map((team) => {
                                return<>
                                <ContactBox name={team.Name} phone={team.Phone} link="mailto:test@example.com" linkText="Email" teamimage={`${team.Image.url}`} />
                                </>
                                })}
                            </Sidebar>
                        </Col>
                        </>
      })}
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default NewsWave
